export const InvoiceLineType = {
    LastMinute: 0,
    FlexAbboDienst: 1,
    SubscriptionDienst: 2,
    CreditUsed: 3,
    ExtraDienst: 4,
    SubscriptionNotUsed: 5,
    FlexNotUsed: 6,
    RegistrationFee: 7,
    Last: 8
}

export const getInvoiceLineTypeDescription = (type) => {
    switch (type) {
        case InvoiceLineType.LastMinute:
            return "Last minute";
        case InvoiceLineType.FlexAbboDienst:
            return "Flex abo";
        case InvoiceLineType.SubscriptionDienst:
            return "Vaste dienst";
        case InvoiceLineType.CreditUsed:
            return "Tegoed ingezet";
        case InvoiceLineType.ExtraDienst:
            return "Extra dienst";
        case InvoiceLineType.SubscriptionNotUsed:
            return "Vaste dienst - niet gebruikt";
        case InvoiceLineType.FlexNotUsed:
            return "Flex abo - niet gebruikt";
        case InvoiceLineType.RegistrationFee:
            return "Inschrijfkosten";
        default:
            return "Onbekend";
    }
}