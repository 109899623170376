import { createSlice } from "@reduxjs/toolkit"

import firebase from 'firebase/app';
import 'firebase/firestore';

const tariffsSlice = createSlice({
    name: 'tariffs',
    initialState: {
        loading: false,
        tariffs: null
    },
    reducers: {
        getTariffsLoading: (state) => {
            state.loading = true;
        },
        getTariffsSuccess: (state, action) => {
            const { data } = action.payload;
            state.tariffs = data;
            state.loading = false;
        }
    }
})

export default tariffsSlice.reducer

const { getTariffsLoading, getTariffsSuccess } = tariffsSlice.actions

export const getTariffs = () => async dispatch => {
    dispatch(getTariffsLoading());

    console.log("Getting tariffs...");
    
    firebase.firestore().collection("tariffs").onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
            let obj = {
                id: doc.id,
                ...doc.data()
            }
            
            data.push(obj);
        });

        data = data.map((tariff, idx) => {
            return {
                ...tariff,
                tariffId: idx
            }
        })

        dispatch(getTariffsSuccess({data}));
    });
}

